import React from 'react';
import Head from "next/head";
import Link from "next/link";

const Home = (props) => {
    const image = '/nfthive-logo.png';

    return (
        <div className={"Page"}>
            <Head>
                <meta id="og-title" property="og:title" content={'Hive Block Producer'} />
                <meta id="og-description" property="og:description" content={'Hive Block Producer - Home'} />
                <meta id="og-image" property="og:image" content={image} />
                <meta id="twitter-image" property="twitter:image" content={image} />
                <meta name="msapplication-TileColor" content="#F6C30D" />
                <meta name="theme-color" content="#1A1A1A"  />
                <link id='page-image' rel="apple-touch-icon" href={image} />
                <meta id="twitter-title" property="twitter:title" content={'Hive Block Producer'}  />
                <meta id="twitter-description" property="twitter:description" content={'Hive Block Producer - Home'} />
                <title>Hive BP Homepage</title>
            </Head>
            <div className={"AssetListHeader"}>
                About us
            </div>
            <div className="FrontItem2">
                <div>
                    <div className="Content">
                        Hive BP is a German Block Producer determined to serve the <a target="_blank" href={'https://wax.io'}>WAX Blockchain</a> community and contribute to the WAX Ecosystem with a robust producer infrastructure as well as dedicated projects to support the NFT ecosystem.
                        We built the <a target="_blank" href={'https://nfthive.io'}>NFTHive</a> platform, a secondary and primary Marketplace, Set Builder, NFT multitool and community site, an open Source <a target="_blank" href={'https://waxdummymarket.com'}>Market Template</a> to help out others to set up their own market for their collection, as well as <a target="_blank" href={'https://waxmarketcap.com'}>WAXMarketCap</a>, a live analytics site for NFT projects on WAX.<br/>
                        Our producer account can be found here: <a target="_blank" href={'https://wax.bloks.io/account/waxhiveguild'}>waxhiveguild</a>.<br/>
                    </div>
                    <div className={"AssetListHeader"}>
                        Code of Conduct
                    </div>
                    <div className="Content" id="code">
                        Hive BP vows to serve the WAX community to the best of our ability.
                        We are independent and represented by our team members alone.
                        Our goal is to forward the success of the WAX blockchain by offering smart NFT solutions that benefit all token holders.
                        Our mission to develop new tools and products for users and partners alike and to connect the community with content creators.
                        We believe modern blockchains must be easily accessible and carbon neutral. We chose WAX because it is simultaneously one of the most used and most efficient blockchains.
                        Our Block Producers are optimized to run blockchain operations without using mining, as per WAX's dPoS consesus algorithm.
                    </div>
                    <div className={'CarbonNeutral'}>
                        <Link href={'https://on.wax.io/co2/'}>
                            <div className={"NextLink"}><img src={'/waxco2.png'}/></div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={"AssetListHeader"} id="tech">Technology</div>
            <div className="FrontItem3">
                <div className="Content" id="code">
                    Hive BP is running several high end nodes and services for the WAX Blockchain:
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>Hyperion Cluster 1</li>
                        <li>AMD Ryzen™ 9 5950X 16-Core</li>
                        <li>128 GB DDR4 ECC</li>
                        <li>3.84 TB NVMe SSD</li>
                        <li><a href="https://wax.hivebp.io/v2/health">Hyperion API</a></li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>Hyperion Cluster 2</li>
                        <li>Intel® Core™ i7-8700 Hexa-Core Coffee Lake</li>
                        <li>128 GB DDR4</li>
                        <li>1 TB NVMe SSD</li>
                        <li><a href="https://wax.hivebp.io/v2/health">Hyperion API</a></li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>Hyperion Cluster 3</li>
                        <li>AMD Ryzen 5 3600 6-Core Processor</li>
                        <li>64 GB DDR4 RAM</li>
                        <li>512 GB NVMe SSD</li>
                        <li>1.92 TB NVMe SSD</li>
                        <li><a href="https://wax.hivebp.io/v2/health">Hyperion API</a></li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>State History Node 1</li>
                        <li>AMD Ryzen™ 9 3900 12-Core</li>
                        <li>128 GB DDR4 ECC</li>
                        <li>1.92 TB NVMe SSD</li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>State History Node 2</li>
                        <li>AMD Ryzen™ 9 3900 12-Core</li>
                        <li>128 GB DDR4 ECC</li>
                        <li>3.84 TB NVMe SSD</li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>State History Node 3</li>
                        <li>AMD Ryzen™ 9 3900 12-Core</li>
                        <li>128 GB DDR4 ECC</li>
                        <li>1 TB NVMe SSD</li>
                        <li>8 TB SATA Enterprise Hard Drive</li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>Atomic API</li>
                        <li>AMD Ryzen™ 9 3900 12-Core</li>
                        <li>128 GB DDR4 ECC</li>
                        <li>1.92 TB NVMe SSD</li>
                        <li><a href="https://atomic.hivebp.io/atomicmarket/docs/swagger/">Atomic API</a></li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>Mainnet Producer 1</li>
                        <li>Intel® Core™ i9-9900K Octa-Core</li>
                        <li>128 GB DDR4 ECC</li>
                        <li>1 TB NVMe SSD</li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>Mainnet Producer 2</li>
                        <li>Intel® Core™ i9-9900K Octa-Core</li>
                        <li>128 GB DDR4 ECC</li>
                        <li>1 TB NVMe SSD</li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>API Node 1</li>
                        <li>AMD Ryzen 9 3900 12 Cores "Matisse" (Zen2)</li>
                        <li>128 GB DDR4 ECC</li>
                        <li>1.92 TB NVMe SSD</li>
                        <li><a href="https://api.hivebp.io/v1/chain/get_info">API V1</a></li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>API Node 2</li>
                        <li>AMD Ryzen 9 3900 12 Cores "Matisse" (Zen2)</li>
                        <li>128 GB DDR4 ECC</li>
                        <li>1.92 TB NVMe SSD</li>
                        <li><a href="https://api2.hivebp.io/v1/chain/get_info">API V1</a></li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>Peer 2 Peer Node 1</li>
                        <li>AMD Ryzen 9 3900 12 Cores "Matisse" (Zen2)</li>
                        <li>128 GB DDR4 ECC</li>
                        <li>1.92 TB NVMe SSD</li>
                        <li>peer1.hivebp.io:9876</li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>Peer 2 Peer Node 2</li>
                        <li>AMD Ryzen 9 3900 12 Cores "Matisse" (Zen2)</li>
                        <li>128 GB DDR4 ECC</li>
                        <li>1.92 TB NVMe SSD</li>
                        <li>peer2.hivebp.io:9876</li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>Testnet Producer</li>
                        <li>AMD Ryzen 5 3600 Hexa-Core "Matisse" (Zen2)</li>
                        <li>64 GB DDR4 RAM</li>
                        <li>512 GB NVMe SSD</li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>Testnet API</li>
                        <li>AMD Ryzen 5 3600 Hexa-Core "Matisse" (Zen2)</li>
                        <li>64 GB DDR4 RAM</li>
                        <li>512 GB NVMe SSD</li>
                        <li><a href="https://api-test.hivebp.io/v1/chain/get_info">API V1</a></li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>Testnet Peer 2 Peer</li>
                        <li>AMD Ryzen 5 3600 Hexa-Core "Matisse" (Zen2)</li>
                        <li>64 GB DDR4 RAM</li>
                        <li>512 GB NVMe SSD</li>
                        <li>peer-test.hivebp.io:9876</li>
                    </div>
                </div>
                <div className={"ServerList"}>
                    <div>
                        <li>IPFS</li>
                        <li>Intel® Core™ i7-6700 Quad-Core Skylake</li>
                        <li>64 GB</li>
                        <li>4 TB SATA 6 Gb/s Enterprise HDD</li>
                        <li><a href="https://ipfs.hivebp.io/ipfs/QmV2anfsAQc3JnfsrFsmbVj4ri8QgtEByXQGyrFuqnegho">IPFS Image Hosting</a></li>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
